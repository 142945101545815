const BASE_PATHNAME = '/referenzen';
const ALL_CATEGORY_PATHNAME = '/alle';

const referenceProjectsConfig = {
  // `basePathname` is implicitly tied to `pages/referenzen` directory. Both must match.
  basePathname: BASE_PATHNAME,
  // this is a pathname for a non-existent category that is supposed to contain all reference projects
  allCategoryPathname: ALL_CATEGORY_PATHNAME,
  // homepage directory for all reference projects
  homepage: `${BASE_PATHNAME}${ALL_CATEGORY_PATHNAME}`,
};

export { referenceProjectsConfig };
