import getConfig from '@/configs/global';

const {
  publicRuntimeConfig: { BASE_URL },
} = getConfig();

export const generateHreflangLinks = () => {
  return (
    <>
      <link rel="alternate" href={BASE_URL} hrefLang="de" />
      <link rel="alternate" href={`${BASE_URL}/at`} hrefLang="de-AT" />
      <link rel="alternate" href={`${BASE_URL}/intl`} hrefLang="en" />
      <link rel="alternate" href={`${BASE_URL}/pl`} hrefLang="pl" />
      <link rel="alternate" href={BASE_URL} hrefLang="x-default" />
    </>
  );
};
