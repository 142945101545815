import getConfig from '@/configs/global';

const {
  publicRuntimeConfig: { BASE_URL },
} = getConfig();

export const generateBreadcrumbStructuredData = breadcrumbs => {
  const breadcrumbsCopy = breadcrumbs?.filter(
    (breadcrumb, i) => !(i === 0 && breadcrumb.metadata.pathname === '/')
  );

  if (!breadcrumbsCopy || breadcrumbsCopy.length <= 1) {
    return null;
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: breadcrumbsCopy.map((breadcrumb, i) => ({
            '@type': 'ListItem',
            position: i + 1,
            name: breadcrumb.title,
            item: `${BASE_URL}${breadcrumb.metadata.pathname}`,
          })),
        }),
      }}
      key="page-jsonld-breadcrumbs"
    />
  );
};

export const generateFaqStructuredData = page => {
  const faqComponent = page.attributes.body.find(
    component => component?.__component === 'faq.frequently-asked-questions'
  );

  if (faqComponent) {
    const mainEntity = faqComponent.questionAndAnswers.map(qna => ({
      '@type': 'Question',
      name: qna.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: qna.answer,
      },
    }));

    return mainEntity.length ? (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity,
          }),
        }}
        key="page-jsonld-faq"
      />
    ) : null;
  }

  return null;
};

export const generateLogoStructuredData = pathname => {
  if (pathname === '/') {
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: BASE_URL,
            logo: `${BASE_URL}/images/klickrent-logo.svg`,
          }),
        }}
        key="page-jsonld-logo"
      />
    );
  }
  return null;
};
